import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PreviewCard from "./PreviewCard";
import { Modal, Button } from "react-bootstrap";

const Movies = () => {
    /*
    * Variables
    */
    const [movies, setMovies] = useState([]);
    const [specialMovies, setSpecialMovies] = useState([]);
    const [show, setShow] = useState(false);

    /*
    * React useEffect for dynamic changes
    */
    useEffect(() => {
        const getMovies = async () => {
            const moviesFromServer = await fetchMovies();
            setMovies(moviesFromServer);
        };
        getMovies();
        const getSpecialMovies = async () => {
            const specialMoviesFromServer = await fetchSpecialMovies();
            setSpecialMovies(specialMoviesFromServer);
        };
        getSpecialMovies();
    }, []);

    /*
    * Helper methods
    */
    // Click handler for special screenings i.e. opera
    const handleSpecialOnClick = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };

    /*
    * API calls
    */
    // Fetch movies from API
    const fetchMovies = async () => {
        const res = await fetch("https://api.kino-bf.de/index.php/movies/list");
        const data = await res.json();

        return data;
    };
    // Fetch special movies from API
    const fetchSpecialMovies = async () => {
        const res = await fetch(
            "https://api.kino-bf.de/index.php/movies/special"
        );
        const data = await res.json();

        return data;
    };

    /*
    * JSX template
    */
    return (
        <>
            <div className="movies-overview-container">
                <h1>Alle Filme</h1>
                <div className="movies-overview">
                    {movies.map(movie => (
                        <Link key={movie.F_id} to={"/screening/" + movie.first}>
                            <PreviewCard
                                key={movie.F_id}
                                title={movie.Titel}
                                link={movie.img_ref}
                            />
                        </Link>
                    ))}
                </div>
            </div>
            <div className="movies-overview-container">
                <h1>Sondervorstellungen</h1>
                <div className="movies-overview">
                    {specialMovies.map(movie => (
                        <div onClick={handleSpecialOnClick} key={movie.F_id}>
                            <PreviewCard
                                key={movie.F_id}
                                title={movie.Titel}
                                link={movie.img_ref}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Oper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Plätze für Übertragungen der MET/ ROH können Sie vor Ort,
                    telefonisch oder per E-Mail erwerben.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Movies;
