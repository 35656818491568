import ReservationItem from "./ReservationItem";
import { BsClock, BsCalendarWeek } from "react-icons/bs";
import { GiFilmProjector } from "react-icons/gi";

const ReservationBox = ({ data, selectedSeats }) => {
    /*
    * JSX template
    */
    return (
        <div className="reservation-box-container">
            <h3>Zusammenfassung</h3>
            <h6>Ausgewählte Vorstellung</h6>
            <div className="reservation-box-info">
                <div>
                    <BsCalendarWeek />

                    {typeof data[0] !== "undefined" && " " + data[0].Datum}
                </div>
                <div>
                    <BsClock />

                    {typeof data[0] !== "undefined" && " " + data[0].Zeit}
                </div>
                <div>
                    Saal 

                    {typeof data[0] !== "undefined" && " " + data[0].Saal}
                </div>
            </div>
            <h6>Ausgewählte Plätze (Reihe | Sitz) </h6>
            <div className="reservation-box-seats-header" />
            <div className="reservation-box-seats">
                {selectedSeats.map((seat, index) => (
                    <ReservationItem key={index} seat={seat} />
                ))}
            </div>
        </div>
    );
};

export default ReservationBox;
