import {
    Navbar,
    Nav,
    Container,
    Form,
    FormControl,
    Button
} from "react-bootstrap";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

const Header = () => {
    /*
    * JSX template
    */
    return (
        <header>
            <Navbar variant="dark" expand="md">
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            alt=""
                            src="https://kino-bf.de/img/logo_fg.png"
                            width="50"
                            height="50"
                            className="d-inline-block align-center"
                        />{" "}
                        Kino Bad Füssing
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">
                                Start
                            </Nav.Link>
                            <Nav.Link as={Link} to="/movies">
                                Filme
                            </Nav.Link>
                            <Nav.Link as={Link} to="/program">
                                Programm
                            </Nav.Link>
                            <Nav.Link as={Link} to="/contact">
                                Kontakt
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <br />
        </header>
    );
};

export default Header;
