import {Link} from 'react-router-dom'
const PreviewHeader = ({title,  link, linkText}) => {
     /*
    * JSX template
    */
    return (
        <div className='preview-header'>
            <h5>{title}</h5>
          
            <div></div>
            <Link to={link}>{linkText}</Link>
        </div>
    ) 
}

export default PreviewHeader