import { useState } from "react";
import axios from "axios";
import { Modal, Button, FormControl, Form } from "react-bootstrap";
import validator from "validator";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";

const ReservationForm = ({ selectedSeats, currID }) => {
    /*
    * Variables
    */
    // user information
    const [firstName, setFirstName] = useState([""]);
    const [lastName, setLastName] = useState([""]);
    const [email, setEmail] = useState([""]);
    const [phone, setPhone] = useState([""]);
    const [code, setCode] = useState([""]);
    // mail validation message
    const [emailError, setEmailError] = useState("");
    // show modal prop
    const [show, setShow] = useState(false);

    // naviagate for changing URL-Param
    let navigate = useNavigate();

    /*
    * Helper methods
    */
    const handleOnChangeFirstName = element => {
        setFirstName(element);
    };
    const handleOnChangeLastName = element => {
        setLastName(element);
    };
    const handleOnChangePhone = element => {
        setPhone(element);
    };
    const handleOnChangeEmail = element => {
        setEmail(element);
    };
    const handleOnChangeCode = element => {
        setCode(element);
    };

    // Form submission handling
    const handleSubmit = e => {
        e.preventDefault();
        showModal();
    };

    const showModal = () => {
        if (email !== "" && validator.isEmail(email) && selectedSeats[0]) {
            setShow(true);
            return; 
        } else {
            
            setEmailError("Bitte geben Sie eine gültige E-Mail Addresse an.");
        }
        alert("Bitte geben Sie alle Information an und überprüfen Sie, dass Sie Plätze ausgewählt haben.")
    };
    const closeModal = () => {
        setShow(false);
    };

    /*
    * API calls
    */
    // actual form submission with API call
    async function postData(url = "", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors",
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json"
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(res => {
            if (res.status === 200) {
                navigate("../success", { replace: true });
            } else {
                navigate("../error/" + "reservation-failed", { replace: true });
            }
        });

        // parses JSON response into native JavaScript objects
    }
    const submitReservation = () => {
        postData("https://api.kino-bf.de/index.php/reservation/new", {
            v_id: currID,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            code: code,
            seats: selectedSeats
        });
    };

    /*
    * JSX template
    */
    return (
        <>
           
            <Form className="personal-details-form" onSubmit={handleSubmit}>
                <h3>Kontaktdetails</h3>
                <InputGroup className="mb-3" key="1">
                    <InputGroup.Text>Vorname</InputGroup.Text>
                    <FormControl
                        aria-label="Vorname"
                        placeholder="Vorname"
                        aria-describedby="inputGroup-sizing-default"
                        value={firstName}
                        onChange={e => handleOnChangeFirstName(e.target.value)}
                    />
                </InputGroup>
                <InputGroup className="mb-3" key="2">
                    <InputGroup.Text id="inputGroup-sizing-default">
                        Nachname
                    </InputGroup.Text>
                    <FormControl
                        aria-label="Nachname"
                        aria-describedby="inputGroup-sizing-default"
                        value={lastName}
                        placeholder="Nachname"
                        onChange={e => handleOnChangeLastName(e.target.value)}
                    />
                </InputGroup>
                <InputGroup className="mb-3" key="3">
                    <InputGroup.Text id="inputGroup-sizing-default">
                        Telefon
                    </InputGroup.Text>
                    <FormControl
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={phone}
                        placeholder="Telefonnummer"
                        onChange={e => handleOnChangePhone(e.target.value)}
                    />
                </InputGroup>

                <InputGroup className="mb-3" key="4">
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                    <FormControl
                        placeholder="E-Mail"
                        aria-label="E-Mail"
                        aria-describedby="basic-addon1"
                        value={email}
                        onChange={e => handleOnChangeEmail(e.target.value)}
                    />
                </InputGroup>
                
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Reservieren
                </Button>
            </Form>
            <Modal show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Zusammenfassung</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>
                        Bitte überprüfen Sie, dass die folgenden Angaben richtig
                        sind.{" "}
                    </h2>
                    <p>Nachname: {lastName}</p>
                    <p>Vorname: {firstName}</p>
                    <p>Telefon: {phone}</p>
                    <p>E-Mail: {email}</p>
                    <div>
                    Plätze: 
                    {selectedSeats.map(seat => (
                        <span>{seat.replaceAll('_', '|') + " "}</span>
                    ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Zurück
                    </Button>
                    <Button variant="primary" onClick={submitReservation}>
                        Reservierung abschicken
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReservationForm;
