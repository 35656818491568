import PreviewHeader from "./PreviewHeader";
import { Carousel } from "3d-react-carousal";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const MoviesPreview = () => {
    /*
    * Variables
    */
    const [movies, setMovies] = useState([]);

    /*
    * useEffect
    */
    useEffect(() => {
        const getMovies = async () => {
            const moviesFromServer = await fetchAllMovies();
            setMovies(moviesFromServer);
        };
        getMovies();
    }, []);

    /*
    * API calls
    */
    // Fetch all current movies from API
    const fetchAllMovies = async () => {
        const res = await fetch("https://api.kino-bf.de/index.php/movies/list");
        const data = await res.json();

        return data;
    };

    /*
    * Helper methods
    */
    // set slides with links
    let slides = movies.map(movie => (
        <Link to={"/screening/" + movie.first}>
            <img src={movie.img_ref} alt="2" />
        </Link>
    ));

    /*
    * JSX template
    */
    return (
        <div>
            <PreviewHeader
                title="Aktuelle Filme"
                link="/movies"
                linkText="Alle Filme"
            />
            <div className="movies-preview" id="movies-preview">
                <Carousel slides={slides} autoplay={false} interval={1000} />
            </div>
        </div>
    );
};

export default MoviesPreview;
