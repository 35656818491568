import MoviesPreview from "./MoviesPreview";
import SchedulePreview from "./SchedulePreview";
import CookieConsent from "react-cookie-consent";

const Overview = () => {
    /*
    * JSX template
    */
    return (
        <main>
            <MoviesPreview />
            <SchedulePreview />
            <CookieConsent>Diese Website benutzt Cookies.</CookieConsent>
        </main>
    );
};
export default Overview;
