import ScreeningTimeCell from "./ScreeningTimeCell";
const ScreeningTimesBar = ({ onSelectTime, times, selectedTime }) => {
    /*
    * JSX template
    */
    return (
        <>
            <div className="screening-times-container">
                {times.map((screening, index) => (
                    <ScreeningTimeCell
                        key={index}
                        onSelectTime={onSelectTime}
                        selectedTime={selectedTime}
                        screening={screening}
                    />
                ))}
            </div>
        </>
    );
};

export default ScreeningTimesBar;
