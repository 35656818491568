import SeatingPlan from "./SeatingPlan";
import ScreeningTimesBar from "./ScreeningTimesBar";
import ReservationBox from "./ReservationBox";
import ReservationForm from "./ReservationForm";
import ReservationMovieDetails from "./ReservationMovieDetails"
import DateBar from "./DateBar";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from 'moment'


const Reservation = () => {
    /*
    * Variables
    */
    // GET paramter i.e. the screening id
    const { id } = useParams();
    // Screening information
    const [title, setTitle] = useState([]);
    const [imageLink, setImageLink] = useState([]);
    const [length, setLength] = useState([]);
    const [fsk, setFsk] = useState([]);
    const [theatre, setTheatre] = useState([]);
    
    const [movieID, setMovieID] = useState([]); 
    const [seatingPlan, setSeatingPlan] = useState([]);
    const [dates, setDates] = useState([]);
    const [times, setTimes] = useState([]);
    // User selected information
    const [screeningData, setScreeningData] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);
    const [selectedScreeningID, setselectedScreeningID] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);

    const [reservationPossible, setReservationPossible] = useState(true); 
    // Form step i.e. 1 = Seating Chart, 2 = User Information
    const [step, setStep] = useState([]);

    /*
    * Setup React methods
    */
    // useEffect for page loading
    useEffect(() =>  {
        init();
        setStep(1);
        //selectDate();
       
    }, []);

    // naviagate for changing URL-Param
    let navigate = useNavigate();

    /*
    * API calls
    */
    // Getting seating plan from server
    const getPlan = async v_id => {
        const plan = await fetch(
            "https://api.kino-bf.de/index.php/screenings/screening/" +
                v_id +
                "/plan"
        );
        return plan;
    };

    // Getting screening times for movie at date from server
    const getTimes = async (f_id, date) => {
        if (date == null) {
            date = dates[0]
            console.log(date)
        }
        let times = await fetch(
            "https://api.kino-bf.de/index.php/screenings/movie/" +
                f_id +
                "/date/" +
                date.replaceAll(".", "-")
        );
        return times;
    };

    // Getting overall screening data from server
    const getData = async v_id => {
        let data = await fetch(
            "https://api.kino-bf.de/index.php/screenings/screening/" +
                v_id +
                "/data"
        );
        return data;
    };

    // Getting screening dates(days) for movie
    const getDates = async f_id => {
        let dates = await fetch(
            "https://api.kino-bf.de/index.php/screenings/movie/" +
                f_id +
                "/dates"
        );
        return dates;
    };

    /*
    * Helper methods
    */

    // Resetting plan and form step to inital
    const reset = () => {
        setStep(1);
        setSelectedSeats([]);
    };
    // Set next form step i.e. the user data form
    const nextStep = () => {
        setStep(step + 1);
    };
    // Set previous form step i.e. the seating selection
    const previousStep = () => {
        setStep(step - 1);
    };

    // Seat selection handling
    const selectSeat = seat => {
        // Reservation is only possible until one hour before start time
        if (!reservationPossible) {
            alert("Für diese Vorstellung ist keine Reservierung mehr möglich.")
            return
        }
        if (!selectedSeats.includes(seat.seat)) {
            setSelectedSeats(selectedSeats => [...selectedSeats, seat.seat]);
        } else {
            const newSeats = selectedSeats.filter(
                seatInArray => seatInArray !== seat.seat
            );
            setSelectedSeats(newSeats);
        }
    };

    // Check if reservation is allowed by time constraint
    const checkReservationPossible = (dateToCheck, timeToCheck) => {
        let completeDate =  dateToCheck.replaceAll(".","-") + " " + timeToCheck; 
        var now = moment(); //todays date
        var end = moment(completeDate, "DD-MM-YYYY HH:mm"); // sreening time date
        var duration = moment.duration(now.diff(end));
        var hours = duration.asHours();
        // check if current time is at least one hour before start time
        if (hours > -1) {
            setReservationPossible(false); 
        } else {
            setReservationPossible(true); 
        }
    }

    /*
    * UI Interaction handling
    */
    // Selecting new date
    const selectDate = async dateClicked => {
        reset();
        setSelectedDate(dateClicked);

        // Get and set screening time
        let movieid = await screeningData[0].F_id; 
        console.log(movieid)
        let resTimes = await getTimes(movieid, dateClicked);
        const timesFromServer = await resTimes.json();
        setTimes(timesFromServer);
        setSelectedTime(timesFromServer[0].Zeit); // Select first as initial
        setselectedScreeningID(timesFromServer[0].V_id);

        // Change search param by navigation…
        navigate("../screening/" + timesFromServer[0].V_id, { replace: true });

        // Get and set seating plan
        let resPlan = await getPlan(timesFromServer[0].V_id);
        const planFromServer = await resPlan.json();
        await setSeatingPlan(planFromServer);

        // Get and set screening data
        let resData = await getData(timesFromServer[0].V_id);
        const dataFromServer = await resData.json();
        setScreeningData(dataFromServer);
        
        checkReservationPossible(dateClicked, timesFromServer[0].Zeit); 
    };

    // Selecting new time
    const selectTime = async timeIDClicked => {
        reset();
        setSelectedTime(timeIDClicked.Zeit);
        setselectedScreeningID(timeIDClicked.V_id);

        // Get and set seating plan
        let resPlan = await getPlan(timeIDClicked.V_id);
        const planFromServer = await resPlan.json();
        setSeatingPlan(planFromServer);

        // Get and set screening data
        let resData = await getData(timeIDClicked.V_id);
        const dataFromServer = await resData.json();
        setScreeningData(dataFromServer);
        
        checkReservationPossible(selectedDate,timeIDClicked.Zeit); 
    };

    // Initialization of Component
    const init = async () => {
        // Get and set screening data
        let resData = await getData(id);
        // Error handling if screening does not exist anymore.
        if (resData.status === 404) {
              navigate("../error/" + "screening-not-found", { replace: true });
        }
        const dataFromServer = await resData.json();
        await setScreeningData(dataFromServer);
        // necessary ?
        await setTitle(dataFromServer[0].Titel);
        await setImageLink(dataFromServer[0].img_ref);
        await setLength(dataFromServer[0].length)
        await setFsk(dataFromServer[0].fsk)
        setMovieID(dataFromServer[0].F_id);
        setTheatre(dataFromServer[0].Saal); 

        // Get and set dates
        let resDates = await getDates(dataFromServer[0].F_id);
        const datesFromServer = await resDates.json();
        setDates(datesFromServer);
        setSelectedDate(dataFromServer[0].Datum); // Select first as initial
        
        // Scroll selected date into view
        const selectedCells = document.getElementsByClassName("date-cell-active");
        selectedCells[0].scrollIntoView(); 

        // Get and set screening times
        let resTimes = await getTimes(
            dataFromServer[0].F_id,
            dataFromServer[0].Datum
        );
        const timesFromServer = await resTimes.json();
        setTimes(timesFromServer);
        setSelectedTime(dataFromServer[0].Zeit); // Select first as initial

        // Get and set seating plan
        let resPlan = await getPlan(id);
        const planFromServer = await resPlan.json();
        setSeatingPlan(planFromServer);
        
        checkReservationPossible(dataFromServer[0].Datum, dataFromServer[0].Zeit); 
    }

    /*
    * JSX template
    */
    return (
        <>
            <div className="reservation-form-container">
                <h1>Plätze reservieren</h1>
                <div className="reservation-form">
                    <div className="reservation-details">
                        <ReservationMovieDetails title={title} imageLink={imageLink} fsk={fsk} length={length} theatre={theatre}/>
                        <div>Datum auswählen:</div>
                        <DateBar
                            dates={dates}
                            selectedDate={selectedDate}
                            onSelectDate={selectDate}
                        />
                        <div>Zeit auswählen: </div>

                        <ScreeningTimesBar
                            onSelectTime={selectTime}
                            selectedTime={selectedTime}
                            times={times}
                        />
                        <ReservationBox
                            data={screeningData}
                            selectedSeats={selectedSeats}
                        />
                    </div>
                    <div className="reservation-user">
                        {step === 1 && (
                            <>
                                <div className="seating-plan">
                                    <SeatingPlan
                                        plan={seatingPlan}
                                        onSelectSeat={selectSeat}
                                        selectedSeats={selectedSeats}
                                    />
                                </div>
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <div className="personal-details-form-container">
                                    <ReservationForm
                                        selectedSeats={selectedSeats}
                                        currID={id}
                                    />
                                </div>
                            </>
                        )}
                        {reservationPossible && 
                        <div
                            className="btn-step"
                            onClick={step === 1 ? nextStep : previousStep}
                        >
                            {step === 1 ? "Weiter" : "Zurück"}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
export default Reservation;
