import {Link} from 'react-router-dom'

const PreviewCard = ({title, link}) => {
    /*
    * JSX template
    */
    return (
        <div className='preview-card'>
            <img src={link}></img>
            <h6>{title}</h6>
           
        </div>
    ) 
}

export default PreviewCard