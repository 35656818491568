import ScreeningTimeCell from "./ScreeningTimeCell";
import ScreeningDateCell from "./ScreeningDateCell";
import { useState, useEffect } from "react";
const ProgrammCard = ({ movie }) => {
    const mode = movie.V_id == null ? "movie" : "date";

    /*
    * JSX template
    */
    return (
        <div className="programm-card">
            <img src={movie.img_ref} alt="2" />
            {mode === "date" && (
                <>
                    <div className="programm-card-details">
                        <h2> {movie.Titel}</h2>
                        <p> {movie.synopsis.substring(0, 150) + "..."}</p>

                        <p>{movie.Datum}</p>

                        <div className="screening-times-container">
                            {movie.Screenings.map((screening, index) => (
                                <ScreeningTimeCell
                                    key={index}
                                    screening={screening}
                                >
                                    {" "}
                                </ScreeningTimeCell>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {mode === "movie" && (
                <>
                    <div className="programm-card-details">
                        <h2> {movie.Titel}</h2>
                        <div className="programm-card-dates">
                            {movie.dates.map((screeningDay, index) => (
                                <ScreeningDateCell
                                    key={index}
                                    screeningDay={screeningDay}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default ProgrammCard;
