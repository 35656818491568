const Seat = ({ seat, selectedSeats, onSelectSeat }) => {
    /*
    * Variables
    */
    let statusClass = "";

    // set CSS class according to seat status
    if (seat.status === "reserved") {
        statusClass = " seat-reserved";
    } else if (seat.status === "blocked") {
        statusClass = " seat-blocked";
    } else if (selectedSeats.includes(seat.seat)) {
        statusClass = "seat-selected";
    }

    /*
    * Helper methods
    */
    // Handle click on disabled seat
    // TODO: use modal for coherence
    const handleDisabledClick = () => {
        alert("Dieser Platz kann nicht ausgewählt werden");
    };
    /*
    * JSX template
    */
    return (
        <>
            <div
                className={"seat " + statusClass}
                onClick={() => {
                    seat.status === "open"
                        ? onSelectSeat(seat)
                        : handleDisabledClick();
                }}
            />
        </>
    );
};

export default Seat;
