import { useState, useEffect } from "react";
import { BsClockHistory, BsFillPersonFill } from "react-icons/bs";
import { GiTheater } from "react-icons/gi";



const ReservationMovieDetails = ({ title, imageLink, length, fsk, theatre }) => {
    /*
    * JSX template
    */
   
    return (
        <>
            <div className="reservation-movie-details">
                <div className="reservation-movie-details-img">
                    <img src={imageLink} alt="2" />
                </div>
                <div className="reservation-movie-details-text">
                    <h2>{title}</h2>
                    <div className="reservation-movie-details-text-infos">
                     
                        Länge
                       
                        <p> <BsClockHistory/> {length} min</p>
                        FSK
                        <p><BsFillPersonFill/> Ab {fsk}</p>
                        Kino
                        <br />
                        <small> <GiTheater/>{theatre === 4 ? "Kino im Großen Kurhaus" : "Filmgalerie"}</small>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReservationMovieDetails;
