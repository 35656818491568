import Row from "./Row";
const SeatingPlan = ({ plan, selectedSeats, onSelectSeat }) => {
    /*
    * Variables
    */
    const seatingPlan = plan;
    
    /*
    * JSX template
    */
    return (
        <div className="seating-plan-container">
            <div className="screen" >Leinwand</div>
            {plan.map(row => (
                <Row
                    seats={row.seats}
                    onSelectSeat={onSelectSeat}
                    selectedSeats={selectedSeats}
                    key={row.row}
                />
            ))}
        </div>
    );
};

export default SeatingPlan;
