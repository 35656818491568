import { useParams } from "react-router-dom";

const Error = () => {
    const { message } = useParams();

    const renderErrorText = message => {
        switch (message) {
            case "reservation-failed":
                return "Die Reservierung konnte nicht angenommen werden. Eventuell kann für die ausgewählte Vorstellung im Moment keine Reservierung angenommen werden, oder die Plätze wurden zwischenzeitlich von jemand Anderem reserviert. Sollte dieses Problem wiederholt auftreten, können Sie es unter info@kino-bf.de melden.";
            case "screening-not-found": 
                return "Die von Ihnen angeforderte Vorstellung konnte nicht geladen werden."
            default:
                return "foo";
        }
    };
    return (
        <>
            <div className="error-container">
            <h2>Es is ein Fehler aufgetreten:</h2>
            {renderErrorText(message)}
            </div>
        </>
    );
};

export default Error;
