import DateCell from "./DateCell";

const DateBar = ({ dates, onSelectDate, selectedDate }) => {
    /*
    * JSX template
    */
    return (
        <>
            <div className="date-bar-container">
                {dates.map((date, index) => (
                    <DateCell
                        date={date}
                        selectedDate={selectedDate}
                        onSelectDate={onSelectDate}
                        key={index}
                    />
                ))}
            </div>
        </>
    );
};

export default DateBar;
