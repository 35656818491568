const DateCell = ({ date, onSelectDate, selectedDate }) => {
    /*
    * JSX template
    */
    return (
        <>
            <div
                className={`date-cell ${selectedDate === date.Datum &&
                    "date-cell-active"}`}
                onClick={() => {
                    onSelectDate(date.Datum);
                }}
            >
                {date.Tag}
                <br/>
                {date.Datum}
            </div>
        </>
    );
};

export default DateCell;
