import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Overview from "./components/Overview";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Movies from "./components/Movies";
import Program from "./components/Program";
import MovieDetails from "./components/MovieDetails";
import Reservation from "./components/Reservation";
import Success from "./components/Success";
import Error from "./components/Error";

function App() {
    /*
    * JSX template
    */
    return (
        <BrowserRouter>
            <div className="main-container">
                <Header />
                <Routes>
                    <Route>
                        <Route path="/" exact element={<Overview />} />
                        <Route path="/movies" exact element={<Movies />} />
                        <Route path="program" exact element={<Program />} />
                        <Route path="contact" exact element={<Contact />} />
                        <Route path="screening/:id" element={<Reservation />} />
                        <Route path="success" element={<Success />} />
                        <Route path="error/:message" element={<Error/>} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
