import { Button } from "react-bootstrap";
const Contact = () => {
    /*
    * JSX template
    */
    return (
        <>
            <div className="contact-container">
                <h1>Kontakt</h1>
                <div>FTB Christian Mitzam</div>
                <div> Adresse: Sonnenstraße 4 94072 Bad Füssing</div>

                <div>Telefon: 08531- 980 555</div>

                <div> E-Mail: <a href="mailto:info@kino-bf.de">info@kino-bf.de</a></div>
                
                © 2022 kino-bf.de
            </div>
        </>
    );
};

export default Contact;
