const ReservationItem = ({ seat }) => {
    /*
    * Varibles
    */
    let seatInfo = seat.split("_");
    let rowNr = seatInfo[0];
    let seatNr = seatInfo[1];
    /*
    * JSX template
    */
    return <div className="reservation-item">{rowNr + " | " + seatNr}</div>;
};

export default ReservationItem;
