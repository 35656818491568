import ScreeningTimeCell from "./ScreeningTimeCell";
const ScreeningDateCell = ({ screeningDay }) => {
    /*
    * JSX template
    */
    return (
        <>
        <div className='screening-date-cell'>
           
            <p>{screeningDay.Datum}</p>
            {screeningDay.screenings.map((screening, index) => (
                <ScreeningTimeCell key={index} screening={screening}></ScreeningTimeCell>
            ))}
        
        </div>
        </>
    );
};
export default ScreeningDateCell;
