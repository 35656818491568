const Success = () => {
    return (
        <>
            <div className="success-container">
                <h1>Reservierung erfolgreich</h1>
                <div>
                    Ihre Reservierung war erfolgreich. Sie sollten in Kürze eine
                    E-Mail bekommen, mit der Sie die Reservierung bestätigen
                    müssen. Wir freuen uns auf Ihren Besuch.
                </div>
            </div>
        </>
    );
};

export default Success;
