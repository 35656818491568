import Seat from "./Seat";

const Row = ({ seats ,selectedSeats, onSelectSeat}) => {
    /*
    * JSX template
    */
    return (
        <>
        <div className='seating-row'>
            {seats.map(seat => (
                <Seat key={seat.seat} onSelectSeat={onSelectSeat} selectedSeats={selectedSeats} seat={seat} />
            ))}
            </div>
        </>
    );
};

export default Row;
