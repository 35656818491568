import PreviewHeader from "./PreviewHeader";
import PreviewCard from "./PreviewCard";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SchedulePreview = () => {
    /*
    * Variables
    */
    const [movies, setMovies] = useState([]);

    /*
    * useEffct on page load
    */
    useEffect(() => {
        const getMovies = async () => {
            const moviesFromServer = await fetchMovies();
            setMovies(moviesFromServer);
        };
        getMovies();
    }, []);

    /*
   * API calls
   */
    // get all movies
    const fetchMovies = async () => {
        const res = await fetch(
            "https://api.kino-bf.de/index.php/movies/current"
        );
        const data = await res.json();
        return data;
    };

    /*
   * JSX template
   */
    return (
        <div>
            <div>
                <PreviewHeader
                    title="Heute"
                    link="/program"
                    linkText="Programm"
                />
                <div className="schedule-preview">
                    {movies.length === 0 && (
                        <p>Keine Vorstellungen für heute verfügbar</p>
                    )}
                    {movies.map(movie => (
                        <Link key={movie.F_id} to={"/screening/" + movie.first}>
                            <PreviewCard
                                title={movie.Titel}
                                link={movie.img_ref}
                            />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SchedulePreview;
