import DateBar from "./DateBar";
import ProgrammCard from "./ProgrammCard";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

const Program = () => {
    /*
    * Variables
    */
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [screenings, setScreenings] = useState([]);
    const [mode, setMode] = useState("date");

    /*
    * react useEffect for dynamic changes
    */
    useEffect(() => {
        const getDates = async () => {
            const datesFromServer = await fetchDates();
            setDates(datesFromServer);
            setSelectedDate(datesFromServer[0].Datum);
            selectDate(datesFromServer[0].Datum);
            
        };
        getDates();
        
        
    }, []);

    /*
    * Helper methods
    */
    const selectDate = dateClicked => {
        setSelectedDate(dateClicked);
        const getScreenings = async () => {
            const screeningsFromServer = await fetchScreenings(dateClicked);
            setScreenings(screeningsFromServer);
        };
        getScreenings();
    };

    const handleChangeView = async () => {
         
         const getScreenings = async () => {
            if (mode === "movie") {
                const screeningsFromServer =  await fetchScreenings(dates[0].Datum);
                await setScreenings(screeningsFromServer)
                await setMode("date")
            } else if (mode === "date") {
                const screeningsFromServer = await fetchAllScreenings();
               
                await setScreenings(screeningsFromServer)
                await setMode("movie")
            }
            };
        await getScreenings()
       // await mode === "date" ? setMode("movie") : setMode("date");
    }
    /*
    * API calls
    */
    // Fetch screening dates from API
    const fetchDates = async () => {
        const res = await fetch(
            "https://api.kino-bf.de/index.php/screenings/dates"
        );
        const data = await res.json();

        return data;
    };
    // Fetch screenings from API
    const fetchScreenings = async queryDate => {
        const res = await fetch(
            "https://api.kino-bf.de/index.php/screenings/" +
                queryDate.replaceAll(".", "-") +
                "/movies"
        );
        const data = await res.json();
        return data;
    };

    const fetchAllScreenings = async () => {
        const res = await fetch(
            "https://api.kino-bf.de/index.php/movies/screenings"
        );
        const data = await res.json();
        console.log(data);
        return data;
    };

    /*
    * JSX template
    */
    return (
        <>
            <div className="programm-overview-container">
                <h1>Aktuelles Programm</h1>
                <Button onClick={handleChangeView}>Ansicht ändern</Button>
                {mode === "date" && (
                    <>
                        <DateBar
                            dates={dates}
                            selectedDate={selectedDate}
                            onSelectDate={selectDate}
                        />
                    </>
                )}
                <div className="programm-card-container">
                    {screenings.map((movieScreening, index) => (
                        <ProgrammCard
                            key={index}
                            movie={movieScreening}
                           
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default Program;
