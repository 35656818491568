import { Link, useParams } from "react-router-dom";

const ScreeningTimeCell = ({ onSelectTime, screening, selectedTime }) => {
    /*
    * JSX template
    */
    
    return (
        <Link to={"/screening/" + screening.V_id}>
            <div
                className={`screening-times-cell ${
                    selectedTime === screening.Zeit
                        ? "screening-times-cell-active"
                        : ""
                }`}
                onClick={() => {
                    onSelectTime(screening);
                }}
            >
                {screening.Datum}
                {screening.Zeit}
                <br />
            </div>
        </Link>
    );
};

export default ScreeningTimeCell;
